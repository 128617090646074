<template>
    <div class="container">
        <main>
            <div class="categories" v-if="hasCategories">
                <div class="title">選擇捐款分類</div>
                <div class="radio-container" v-if="form.category_s != 0">
                    <template  v-for="(category,index) in form.category_s" :key="index">
                        <custom-radio
                            v-model:selected="form.category"
                            class="category-radio"
                            type="block"
                            :value="index"
                            v-if="category"
                        >
                            {{ category }}
                        </custom-radio>
                    </template>
                </div>
                <div class="radio-container" v-else style="color:red;font-size:25px;text-align:center;">
                   沒有分類數據！
                </div>
                <div class="form-item" style="margin-top: 2vh;">
                    <div class="label">筹款對象</div>
                    <custom-selection
                        v-model="form.target"
                        :options="form.target_object"
                    ></custom-selection>
                </div>
                <div class="form-item" style="margin-top: 1.6vh;">
                    <div class="label">電郵（會發回收據）</div>
                    <custom-input v-model="form.email"></custom-input>
                </div>
            </div>
            <div class="amounts">
                <div class="title">捐款金額</div>
                <div class="radio-container">
                    <custom-radio
                        class="amount-radio"
                        v-model:selected="form.amount"
                        type="block"
                        v-for="amount in [
                            '100',
                            '200',
                            '300',
                            '500',
                        ]"
                        :value="amount"
                        :key="amount"
                        @click="form.customAmount = ''"
                    >
                        HKD&nbsp;{{ amount }}
                    </custom-radio>
                </div>
                <div class="form-item" style="margin-top: 2vh;width:60%;float:left;" v-if="!hasCategories">
                    <div class="label">筹款對象：<span style="color:red;">{{form.name}}</span></div>
                </div>
                <div class="form-item" :class="hasCategories?'':'formitem'">
                    <div class="label">其他金額</div>
                    <custom-input-number
                        v-model="form.customAmount"
                        @focus="form.amount = ''"
                    ></custom-input-number>
                </div>
                <div class="form-item" style="width:40%;float:left;" v-if="!hasCategories">
                    <div class="label">電郵</div>
                    <custom-input v-model="form.email"></custom-input>
                </div>
                <div class="form-item" :class="hasCategories?'':'formitems'">
                    <div class="label">持卡人姓名</div>
                    <custom-input v-model="form.real_name"></custom-input>
                </div>
                
            </div>
            <div class="info-1">
                <!-- <div class="form-item">
                    <div class="label">電郵（會發回收據）</div>
                    <custom-input></custom-input>
                </div> -->
                <!-- <div class="form-item">
                    <div class="label">筹款對象</div>
                    <custom-selection
                        v-model="form.target"
                        :options="[
                            { label: 'test1', value: 1 },
                            { label: 'test2', value: 2 },
                            { label: 'test3', value: 3 },
                            { label: 'test4', value: 4 },
                            { label: 'test5', value: 5 },
                        ]"
                    ></custom-selection>
                </div> -->
                <!-- <div class="form-item">
                    <div class="label">形式</div>
                    <custom-input></custom-input>
                </div> -->
                <div class="form-item payment-method">
                    <!-- // TODO: 还没有添加信用卡和Paypal图片 -->
                    <!-- <custom-radio
                        v-model:selected="form.paymentMethod"
                        :value="1"
                    >
                        信用卡
                    </custom-radio> -->
                    <!-- <custom-radio
                        v-model:selected="form.paymentMethod"
                        :value="2"
                    >
                        PayPal
                    </custom-radio> -->
                </div>
            </div>
            <div class="info-2">
                 <!-- <div class="form-item">
                    <div class="label">持卡人</div>
                    <custom-input></custom-input>
                </div> -->
                <!-- <div class="form-item">
                    <div class="label">付款卡</div>
                    <custom-input></custom-input>
                </div>
                <div class="form-item">
                    <div class="label">持卡人</div>
                    <custom-input></custom-input>
                </div>
                <div class="form-item">
                    <div class="label">到期日</div>
                    <custom-input></custom-input>
                </div>
                <div class="form-item">
                    <div class="label">安全驗證CVV/CCV</div>
                    <custom-input></custom-input>
                </div> -->
                <div class="form-item">
                    <!-- <custom-check-box>
                        本人已閲讀并同意受iMeddy服務相關所有條款及細則約束。
                    </custom-check-box> -->
                    <label class="custom-check-box-container">
                        <input
                            type="checkbox"
                            v-model="form.clause"
                            :value="value"
                            class="custom-check-box-input"
                        />
                        <div class="fake-custom-check-box"></div>
                        <slot>本人已閲讀并同意受iMeddy服務相關所有條款及細則約束。</slot>
                    </label>
                </div>
            </div>
            <primary-button radius="none" :loading="form.loadingbut" @click="button" class="confirm-button">
                {{form.loadingbuttext}}
            </primary-button>
        </main>
    </div>
</template>

<script>
import api from "@/api";
import qs from 'qs';
import { request } from 'http';
// TODO: 还没有进行数据绑定。
export default {
    data() {
        return {
            f_id: undefined,
            form: {
                loadingbut:false,
                category: 0,
                category_s:[],
                amount: 0,
                customAmount: "",
                isCustomAmount: false,
                paymentMethod: "",
                target: "",
                clause:false,
                name:'',
                target_object:[],
                email:'',
                real_name:'',
                loadingbuttext:'確認捐款'
            },
            hasCategories: false,
        };
    },
    computed: {
        category(){
            return this.form.category;
        }
    },
    watch:{
        category(val){
            this.form.target = '';
            api.fundraise.getCategorysFundraise('?pageNum=1'+'&pageSize=100'+'&state=50010'+'&category='+val).then(response => {
                let responses = response.data;
                if(responses.count > 0){
                    let target = [];
                    for(let i=0; i<responses.results.length; i++){
                        target .push ({
                            label:responses.results[i].project.title,value:responses.results[i].project.id
                        });
                    }
                    this.form.target_object = target;
                }else{
                    this.form.target_object = [];
                }
            });
        }
    },
    methods: {
        button(){
            // 如果已经点击了捐款 
            if(this.form.loadingbut === true){
                return '';
            }
            if(!this.form.category && !this.form.target && !this.form.name){
                this.$message.error('請選擇分類或者籌款對象!');
                return '';
            }
            if(!this.form.amount && (!this.form.customAmount && this.form.customAmount !== 0)){
                this.$message.error('請填寫或者輸入捐款金額');
                return '';
            }
            if(!this.form.amount && this.form.customAmount<=0){
                this.$message.error('捐款金額必須大於0');
                return '';
            }
            if(!this.form.email){
                this.$message.error('請填寫郵箱!');
                return '';
            }
            if(!this.form.real_name){
                this.$message.error('請輸入持卡人姓名!');
                return '';
            }
            if(!this.form.clause){
                this.$message.error('請勾選條款!');
                return '';
            }
            var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if (!verify.test(this.form.email)) {
                this.$message.error('請輸入有效的電子郵件地址。')
                return 'error';
            }
            this.form.loadingbut = true;
            this.form.loadingbuttext = '处理中...';
            
            let params = {};
            if(this.$route.params.type == 1){
                if(this.form.target){
                    params = {
                    'amount':this.form.amount?this.form.amount:this.form.customAmount,
                    'currency':'HKD',
                    'fundraise':this.form.target,
                    'email':this.form.email,
                    'real_name':this.form.real_name,
                    }
                }else{
                    params = {
                    'amount':this.form.amount?this.form.amount:this.form.customAmount,
                    'currency':'HKD',
                    'category':this.form.category,
                    'email':this.form.email,
                    'real_name':this.form.real_name,
                    }
                }
            }else{
                params = {
                   'amount':this.form.amount?this.form.amount:this.form.customAmount,
                   'currency':'HKD',
                   'fundraise':this.f_id,
                   'email':this.form.email,
                   'real_name':this.form.real_name,
                }
            }
            
            params= qs.stringify(params, { indices: false });
            api.paypal.postPayment(params).then(request =>{

                if(!request.data){
                    this.$message.error('操作异常请重新进行捐款！');
                    this.form.loadingbuttext = '確認捐款';
                    this.form.loadingbut = false;
                    return '';
                }

                let url = request.data.redirectUrl;
                
                var strRegex = "^((https|http|ftp|rtsp|mms)?://)"
                + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" // ftp的user@
                + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
                + "|" // 允许IP和DOMAIN（域名）
                + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
                + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
                + "[a-z]{2,6})" // first level domain- .com or .museum
                + "(:[0-9]{1,4})?" // 端口- :80
                + "((/?)|" // a slash isn't required if there is no file name
                + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
                var re = new RegExp(strRegex);

                location.href=request.data.redirectUrl;
                // this.$message.success('捐款成功！');
                // this.$router.push({name:'donateSuccess'});
            });
        }
    },
    created:function (){
        // 所有分類數據
        api.category.getCategorys().then(response => {
            if(response.data.count == 0){
                this.form.category_s = '0';
            }else{
                for(let i = 0; i<response.data.results.length; i++){
                    if(response.data.results[i].fundraisesCount > 0){
                        this.form.category_s[response.data.results[i].id] = response.data.results[i].name;
                    }
                }
            }
        })
        let id = this.$route.params.id;
        if(id && this.$route.params.type != 1){
            api.fundraise.getFundraiseDetails(id).then(response => {
                this.form.name = response.data.project.title;
                this.f_id = response.data.id;
            });
        }
        if(this.$route.params.type == 1){
            this.hasCategories = true;
        }else{
            this.hasCategories = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    min-height: calc(100vh - 48px);
    background-image: url("../assets/img/new-donation-background.png");
    background-size: cover;
    background-position: center;
    padding: 96px 16px 16px;
    @include fr-c-c;
}
.formitem{
    float:left;
    width:40%;
}
.formitems{
    float:right;
    width:40%;
}
main {
    position: relative;
    background-color: $white;
    width: 80vw;
    max-width: 900px;
    min-height: 60vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 32px 32px 88px;
    gap: 16px;
    .title {
        color: $green-500;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }
    .info-1,
    .info-2 {
        margin: auto;
        @include fc-s-st;
    }
    .confirm-button {
        position: absolute;
        right: 32px;
        bottom: 32px;
        width: 150px;
    }
}
.categories {
    .radio-container {
        @include fr-b-c;
        row-gap: 16px;
        flex-wrap: wrap;
        .category-radio {
            width: 45%;
        }
    }
}
.amounts {
    &:first-child {
        grid-column: 1 / 3;
        .radio-container .amount-radio {
            width: 23%;
        }
    }
    .radio-container {
        @include fr-b-c;
        row-gap: 16px;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .amount-radio {
            width: 45%;
        }
    }
}
.payment-method {
    @include fc-s-s;
    row-gap: 16px;
}
@media screen and (max-width: 600px) {
    main {
        width: 100%;
        grid-template-rows: auto auto auto;
        grid-template-columns: auto;
        .amounts:first-child {
            grid-column: auto;
            .radio-container {
                .amount-radio {
                    width: 45%;
                }
            }
        }
    }
}
</style>